<template>
<div class="community-create">
   <div class="notice vertical-center">
  <img src="~assets/images/index/notice.png" class="notice-img"/>
  <span>发布须知：电子资源为电子书,软件包专区，发错板块将被关闭！</span>
  </div>
  <van-field v-model="title" maxlength='30'  placeholder="请输入标题" border="{{ false }}"  class="title" />
  <van-divider class="line" />
  <van-field v-model="content" rows="5"  maxlength='200' show-word-limit placeholder="请输入正文内容..." type="textarea" border="{{ false }}" class="content" />
  <van-uploader v-model="fileList" accept="file" :upload-text="utext" :max-count="5" :disabled="udisabled" :deletable="!udisabled" upload-icon="https://resources.suantan.net/mini-program/index/h5-upload.png" :after-read="afterRead" :before-delete="deleteImg" :preview-full-image="false"  />
<!--    <template #preview-cover="{ file,index }">-->
<!--      <div class="vantbtn" @click.stop="open(index)">预览</div>-->
<!--    </template>-->
<!--  </van-uploader>-->
  <van-cell title="资源分类" is-link :value="category_value" @click="changeCategory()" title-class="cell-l" />
  <van-divider class="line" />
  <van-cell title="资源积分" title-class="cell-l">
    <input type="number" v-model="credit"  onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" maxlength='40' placeholder="请输入商品积分" border="{{ false }}"  class="create-input" />
  </van-cell>
  <van-divider class="line" />
  <div class="btmbox"></div>
  <van-action-sheet v-model:show="categoryShow" :actions="category" cancel-text="取消" description="选择资源分类" close-on-click-action @cancel="onClose"
  @select="categorySelect" />
  <div class="foot">
    <div class="footer vertical-center">
    <div class="footer-l vertical-center" @click="sub('0')" >
      <img class="footer-l-img" src="~assets/images/index/draft.png" />
      存草稿
    </div>
    <div class="footer-r vertical-center" @click="sub('1')" >
      发布
    </div>
    
  </div>
  <div class="safety-height"></div>
  </div>
  <van-toast id="van-toast" />
</div>
</template>

<script>
import UserInfo from "components/userInfo";
import _ from 'lodash'
export default {
  name: "community.create",
  data(){
    return{
        id:'',
        fileList: [],
        categoryShow: false,
        title:'',
        content:'',
        category:[
          {   type:"book",
            name:"电子书"
          },
          {   type:"package",
            name:"软件包"
          }
        ],
      type:'',
        category_value:'请选择分类',
        credit:'',
        fileUrl:[],
        osscdn: "",
        subbtn:true,
        utext:'',
      udisabled:false
    }
  },

    created() {
      if (this.$route.query.id) {
        this.id=this.$route.query.id
        this.info()
      }
  },
  methods:{
    open(index){
      console.log(this.fileUrl[index].file)
        window.open(this.fileUrl[index].file)
    },
    download(event){
      //下载文件
      let url = event.file
      let filename = event.name
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", filename);
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
    //详情
    info(){
      let url = this.$api.communityelectron +'/'  + this.$api.edit   + '/' + this.id
      this.$http.get(url, true).then(res => {
        if(res.data.success){

                this.type=res.data.data.type
              this.credit=res.data.data.credit
              this.title=res.data.data.title
              this.content=res.data.data.content
              this.status=res.data.data.status
              this.fileUrl=res.data.data.resources
              this.fileList=res.data.data.resources
          if(res.data.data.type == 'book'){
            this.category_value = '电子书'
          }else if(res.data.data.type == 'package'){
            this.category_value = '软件包'
          }
        }
      })
    },
    //提交
    sub: _.debounce(function(status){
      if(!this.subbtn){
        return
      }
      if(this.type == ''){
        this.$toast('请选择资源分类');
        return
      }
      if(this.title == ''){
        this.$toast('请输入标题');
        return
      }
      if(this.title.length < 4){
        this.$toast('标题为4-30字');
        return
      }
        if (status == 1){

          if(this.credit == ''){
            this.$toast('请输入资源积分');
            return
          }

          if(this.content == ''){
            this.$toast('请输入正文');
            return
          }

          if(this.fileUrl.length < 1){
            this.$toast('必须上传资源');
            return
          }
        }

      let url = this.$api.communityelectron
      if(this.id){
        if( this.status == 1 &&  status == 0){
          this.$toast('已发布不可再存草稿');
          return
        }
        let data = {
          type:this.type,
          credit:this.credit,
          title:this.title,
          content:this.content,
          status:status,
          resources:this.fileUrl,
          id:this.id
        }
        this.$http.put(url,data, true).then(res => {
          if(res.data.success){
            this.subbtn = false
            this.$toast('修改成功');
              this.$store.commit('M_ACT','2')
               if(status == 0){
            this.$store.commit('M_ACTS','1')
          }else{
            this.$store.commit('M_ACTS','0')
          }
              this.$router.go(-1)
          }else{
            this.$toast(res.data.message);
          }
        }).catch(error =>{
          if (error.response.status == 422) {
            this.$toast(error.response.data.message);
          }
        })
      }else{
        let data = {
          type:this.type,
          credit:this.credit,
          title:this.title,
          content:this.content,
          status:status,
          resources:this.fileUrl
        }
        this.$http.post(url,data, true).then(res => {
          if(res.data.success){
               this.subbtn = false
                 if(status == 0){
            this.$toast('保存成功');
          }else{
            this.$toast('发布成功');
          }
            
            this.$store.commit('M_ACT','2')
            this.$router.go(-1)
          }else{
            this.$toast(res.data.message);
          }
        }).catch(error =>{
          if (error.response.status == 422) {
            this.$toast(error.response.data.message);
          }
        })
      }
      
    },500),
    // previewfile(event,index){
    //   console.log(this.fileList,event.content,index)
    // },
    afterRead(event) {
      this.utext = '正在上传'
      this.udisabled = true
       let data =new FormData()
      data.append('file', event.file)
      data.append('type', 'community')
      this.$http.post(this.$api.uploadresource, data, true).then(res => {
          if(res.data.success){
              this.fileUrl= this.fileUrl.concat(res.data.data)
              this.fileList = JSON.parse(JSON.stringify(this.fileUrl))
              this.utext = ''
              this.udisabled = false
          } else {
            //   wx.showToast({
            //       title: '上传失败',
            //       icon: 'error',
            //       duration: 2000
            //   })
          }
      })
      },
    //      //  删除图片
    deleteImg(event,index) {
        let list = this.fileList
        let url = this.fileUrl
        list.splice(index.index,1)
        url.splice(index.index,1)
        this.fileList=list
          this.fileUrl=url

    },
    //标题
    // titleChange(event){
    //   this.setData({
    //     title:event.detail
    //   })
    // },
    //内容
    // contentChange(event){
    //   this.setData({
    //     content:event.detail
    //   })
    // },
    //分类
    changeCategory(){
        this.categoryShow=true
    },
    onClose() {
        this.categoryShow=false
    },
    
      categorySelect(event) {
        this.type=event.type,
          this.category_value=event.name
      },
 
  },
  components:{
    UserInfo,
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/community/create.scss";
.community-create .notice{
  padding: 20/$r 15/$r;
}
</style>
<style lang="scss">
$r: 750/16/1rem;
.van-cell:after{
  border: unset;
}
.cell-l{
  width: 80px;
  flex: none;
}
input{
  border: none;
}
.van-cell__value{
  text-align: left;
}
   .van-uploader {
      margin: 0 50/$r;
    }
  
    .van-uploader__preview {
      width: 200/$r;
      height: 200/$r;
    }
  
    .van-uploader__preview-image, .van-uploader__upload {
      width: 200/$r!important;
      height: 200/$r!important;
    }
    .van-uploader__file{
      width: 100%;
      height: 100%;
      position: relative;
    }
  .vantbtn{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    font-size: 12px;
  }
</style>